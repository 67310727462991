import Process1 from '../../assets/image/saasTwo/www.png';
import Process2 from '../../assets/image/saasTwo/process-2.svg';
import Process3 from '../../assets/image/saasTwo/process-3.svg';

import FeatureIconOficial from '../../assets/image/saasTwo/whatsapp-oficial.png';
import FeatureIcon1 from '../../assets/image/saasTwo/whatsapp.png';
import FeatureIcon1Plus from '../../assets/image/saasTwo/whatsapp_plus.png';
import FeatureIcon2 from '../../assets/image/saasTwo/chatbot.png';
import FeatureIcon3 from '../../assets/image/saasTwo/sending.png';
import FeatureIcon4 from '../../assets/image/saasTwo/contact.png';
import FeatureIcon5 from '../../assets/image/saasTwo/territorio.png';
import FeatureIcon6 from '../../assets/image/saasTwo/people.png';
import FeatureIcon7 from '../../assets/image/saasTwo/teamwork.png';
import FeatureIcon8 from '../../assets/image/saasTwo/infinite.png';
import FeatureIcon9 from '../../assets/image/saasTwo/instagram.png';
import FeatureIcon10 from '../../assets/image/saasTwo/telegram.png';
import FeatureIcon11 from '../../assets/image/saasTwo/messenger.png';

import Screenshot1 from '../../assets/image/saasTwo/dashboards_print.png';
import Screenshot2 from '../../assets/image/saasTwo/chat.png';
import Screenshot3 from '../../assets/image/saasTwo/campanhas.png';
import Screenshot4 from '../../assets/image/saasTwo/invoice_print.png';

import AuthorOne from '../../assets/image/saasTwo/militao.jpg';
import AuthorTwo from '../../assets/image/saasTwo/matheus.jpg';
import AuthorThree from '../../assets/image/saasTwo/rev.jpg';

import { ic_chat } from 'react-icons-kit/md/ic_chat';
import { ic_send } from 'react-icons-kit/md/ic_send';
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
import { briefcase } from 'react-icons-kit/fa/briefcase';
import { fileTextO } from 'react-icons-kit/fa/fileTextO';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Funcionalidades',
    path: '#feature_section',
    offset: '0',
  },
  {
    label: 'Planos',
    path: '#pricing_section',
    offset: '0',
  },
  {
    label: 'Depoimentos',
    path: '#testimonial_section',
    offset: '0',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '0',
  },
];

export const PROCESS_ITEMS = [
  {
    image: Process1,
    title: 'Acesse o site',
    description:
      'Nosso sistema é totalmente web, acesse de qualquer dispositivo com acesso a internet, não necessita de nenhuma instalação!',
  },
  {
    image: Process2,
    title: 'Preencha as informações',
    description:
      'Algumas informações são nescessárias para abertura da sua conta, mas fique tranquilo isso leva no máximo 2 minutos!',
  },
  {
    image: Process3,
    title: 'Agora comece sua jornada',
    description:
      'Parabéns, agora você pode aproveitar seu teste grátis de 7 dias e testar bastante a nossa ferramenta!',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: false,
    name: 'Fit (Mensal)',
    description: 'Plano ideal para quem quer enviar campanhas de marketing!',
    price: 'R$ 79,90',
    priceLabel: 'Valor cobrado mensalmente',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 5',
      },
      {
        content: 'Qtd. Departamentos: 5',
      },
      {
        content: 'Qtd. WhatsApp: 1',
      },
      {
        content: 'Qtd. Campanhas: 10',
      },
      {
        content: 'Qtd. Robôs: 1',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
  {
    name: 'Pro (Mensal)',
    description:
      'Plano ideal para empresas que queiram realizar atendimento via Whatsapp! ',
    price: 'R$ 139,90',
    priceLabel: 'Valor cobrado mensalmente',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 15',
      },
      {
        content: 'Qtd. Departamentos: 10',
      },
      {
        content: 'Qtd. WhatsApp: 2',
      },
      {
        content: 'Qtd. Campanhas: 50',
      },
      {
        content: 'Qtd. Robôs: 2',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
  {
    name: 'Premium (Mensal)',
    description:
      'Plano ideal para grandes empresas que queiram realizar atendimento via Whatsapp! ',
    price: 'R$ 539,90',
    priceLabel: 'Valor cobrado mensalmente',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 40',
      },
      {
        content: 'Qtd. Departamentos: 20',
      },
      {
        content: 'Qtd. WhatsApp: 10',
      },
      {
        content: 'Qtd. Campanhas: 80',
      },
      {
        content: 'Qtd. Robôs: 10',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: false,
    name: 'Fit (Trimestral)',
    description: 'Plano ideal para quem quer enviar campanhas de marketing!',
    price: 'R$ 219,70',
    priceLabel: 'Valor cobrado a cada 3 meses',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 5',
      },
      {
        content: 'Qtd. Departamentos: 5',
      },
      {
        content: 'Qtd. WhatsApp: 1',
      },
      {
        content: 'Qtd. Campanhas: 10',
      },
      {
        content: 'Qtd. Robôs: 1',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
  {
    name: 'Pro (Trimestral)',
    description:
      'Plano ideal para empresas que queiram realizar atendimento via Whatsapp! ',
    price: 'R$ 299,90',
    priceLabel: 'Valor cobrado a cada 3 meses',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 15',
      },
      {
        content: 'Qtd. Departamentos: 10',
      },
      {
        content: 'Qtd. WhatsApp: 2',
      },
      {
        content: 'Qtd. Campanhas: 50',
      },
      {
        content: 'Qtd. Robôs: 2',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
  {
    name: 'Premium (Trimestral)',
    description:
      'Plano ideal para grandes empresas que queiram realizar atendimento via Whatsapp! ',
    price: 'R$ 1499,90',
    priceLabel: 'Valor cobrado a cada 3 meses',
    buttonLabel: 'TESTE 7 DIAS GRÁTIS',
    url: 'https://app.multdesk.com.br/signup',
    listItems: [
      {
        content: 'Qtd. Atendentes: 40',
      },
      {
        content: 'Qtd. Departamentos: 20',
      },
      {
        content: 'Qtd. WhatsApp: 10',
      },
      {
        content: 'Qtd. Campanhas: 80',
      },
      {
        content: 'Qtd. Robôs: 10',
      },
      {
        content: '10GB Armazenamento',
      },
      {
        content: 'Suporte Personalizado',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'Como entrar em contato com o suporte?',
    description:
      'Caso precise de ajuda entre em contato com nosso WhatsApp ou através do nosso email support@multdesk.com.br',
  },
  {
    title: 'O sistema possui fidelidade?',
    description:
      'Não, você pode cancelar e reativar sua assinatura a qualquer momento em nossa plataforma!\n Se você efetuar o cancelamento durante o período de cobrança, o serviço permanecerá disponível até o final desse período',
  },
  {
    title: 'Preciso instalar algo em meu computador?',
    description:
      'Não, nossa plataforma é totalmente web, toda magia acontece em nossos servidores!',
  },
  {
    title: 'Funciona apenas com WhatsApp Bussiness?',
    description: 'Não, funciona para ambas versões!',
  },
  {
    title: 'Funciona com a versão beta do whatsApp?',
    description:
      'O sistema já está todo preparado para receber a versão beta, está em fase de testes em breve a atualização será disponibilizada!',
  },
  {
    title: 'Há limites de mensagens?',
    description: 'Não, as mensagens são ilimitadas!',
  },
];

export const FOOTER_WIDGET = [
  {
    title: 'About Us',
    menuItems: [
      {
        url: 'mailto:support@multdesk.com.br',
        text: 'Support',
      },
      {
        url: 'https://danielmadureira.dev',
        text: 'Copyright',
      },
      {
        url: 'https://multdesk.com.br/politicas-privacidade',
        text: 'Políticas de Privacidade',
      },
    ],
  },
  {
    title: 'Redes Sociais',
    menuItems: [
      {
        url: 'https://www.instagram.com/multdesk',
        text: 'Instagram',
      },
      {
        url: 'https://www.facebook.com/multdeskatendimento/',
        text: 'Facebook',
      },
    ],
  },
];

export const FEATURES = [
  {
    icon: FeatureIconOficial,
    title: 'WhatsApp - Api Oficial',
    description: 'Envie e receba mensagens por meio de uma versão da Plataforma do WhatsApp Business hospedada na nuvem do Facebook.',
  },
  {
    icon: FeatureIcon1,
    title: 'WhatsaApp',
    description: 'Tenha diversos atendentes em um único numero de WhatsApp.',
  },
  {
    icon: FeatureIcon1Plus,
    title: 'WhatsaApp por Setor',
    description:
      'Mais de um whatsapp conectado em nosso sistema, cada setor pode ter seu whatsapp.',
  },
  {
    icon: FeatureIcon2,
    title: 'ChatBot',
    description:
      'Automatize seu atendimento e atenda 24 horas por dia, não perca nenhum contato por falta de resposta!',
  },
  {
    icon: FeatureIcon3,
    title: 'Envios em Massa',
    description: 'Crie campanhas de marketing e faça envios em massa!',
  },
  {
    icon: FeatureIcon4,
    title: 'Contatos',
    description: 'Organize e gerencia seus contatos através da plataforma.',
  },
  {
    icon: FeatureIcon5,
    title: 'Atendimento por Setor',
    description:
      'Equipe eficiente é equipe organizada, separa por setor seus atendentes.',
  },
  {
    icon: FeatureIcon6,
    title: 'Fila de atendimento',
    description:
      'Atenda seus clientes de forma organizada, quando há uma fila, e ela é organizada, tudo fica mais fácil.',
  },
  {
    icon: FeatureIcon7,
    title: 'Chat Interno',
    description: 'Interaja com sua equipe diretamente pelo sistema.',
  },
  {
    icon: FeatureIcon8,
    title: 'Mensagens ilimitadas',
    description:
      'Envio de mensagens totalmente ilimitadas e sem custos adicionais!',
  },
  {
    icon: FeatureIcon9,
    title: 'Instagram (Em desenvolvimento)',
    description: 'Atenda seus clientes diretamente pelo instagram.',
  },
  {
    icon: FeatureIcon10,
    title: 'Telegram (Em desenvolvimento)',
    description: 'Atenda seus clientes diretamente pelo telegram.',
  },
  {
    icon: FeatureIcon11,
    title: 'Messenger (Em desenvolvimento)',
    description: 'Atenda seus clientes diretamente pelo Messenger.',
  },
];

export const SCREENSHOTS = [
  {
    icon: ic_chat,
    title: 'Chat',
    image: Screenshot2,
  },
  {
    icon: ic_dashboard,
    title: 'Dashboards',
    image: Screenshot1,
  },
  {
    icon: ic_send,
    title: 'Envios em massa',
    image: Screenshot3,
  },
  {
    icon: fileTextO,
    title: 'Faturas',
    image: Screenshot4,
  },
];

export const TESTIMONIALS = [
  {
    title: 'Simples e Prático',
    review:
      'Utilizando o recurso de fila, consigo atender meus clientes de forma organziada e prática!',
    name: 'Thiago Militão',
    designation: 'Consultor Financeiro',
    avatar: `${AuthorOne}`,
  },
  {
    title: 'Design moderno e Campanhas de Marketing',
    review:
      'Gostei muito do design do sistema, simples e prático, agora consigo criar minhas campanhas de marketing e disparar para meus clientes!',
    name: 'Matheus',
    designation: 'Marketing Digital',
    avatar: `${AuthorTwo}`,
  },
  {
    title: 'Notificação via Whatsapp',
    review:
      'Com o recurso de notificação utilizando o whatsapp deixo meus clientes informados sobre o status de suas solicitações.',
    name: 'R&V Contabilidade',
    designation: 'Empresa de contabilidade',
    avatar: `${AuthorThree}`,
  },
];
